import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ArchiveTopBanner from "../../components/events/archivetopbanner";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/events.png",
};
const canonicalUrl = "https://hasura.io/events/archive/";
const Archive = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura Events Archive | Hasura GraphQL Engine"
      description="Interested in learning more about Hasura & GraphQL? Take a look at our events below! We have both live and recorded events on everything GraphQL & Hasura."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <ArchiveTopBanner />
  </Layout>
);

export default Archive;
