import React from "react";
import { Link } from "gatsby";
import IndivArchive from "./indivarchive";
import "./styles.scss";
const pastEventState = [
  {
    demoType: "Webinar",
    circleBgColor: "eventYellowBgColor",
    title: "Enterprise Grade Authorization",
    desc: "Learn how to model popular enterprise authorization systems like RBAC, ABAC, AWS IAM and GCP IAM in Hasura to map your authorization requirements.",
    register: "View Recording",
    registerLink: "/events/webinar/authorization-modeling-hasura/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    circleBgColor: "eventYellowBgColor",
    title: "GraphQL in Production",
    desc: "Learn about the powerful features that allow organisations to build and maintain secure, fast and highly scalable apps using our GraphQL API",
    register: "View Recording",
    registerLink: "/events/webinar/graphql-in-production-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Conference",
    circleBgColor: "eventRedBgColor",
    title: "HasuraCon'20: The first Hasura user conference",
    desc: "Our first user conference featuring a week of talks and hands-on workshops about pushing the boundaries of GraphQL forward.",
    register: "View all Talks",
    registerLink: "https://www.youtube.com/playlist?list=PLTRTpHrUcSB9BmfyXmhplz874ySHeKlYx",
  },
  {
    demoType: "Community Call",
    circleBgColor: "eventRedBgColor",
    title: "Hasura Community Call - September 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=8rfAkAiE2_I",
  },
  {
    demoType: "Webinar",
    circleBgColor: "eventYellowBgColor",
    title: "Data Federation with Hasura",
    desc: "Join data across your databases, microservices & SaaS services (GraphQL & REST) with Hasura",
    register: "View Recording",
    registerLink: "/events/webinar/data-federation-hasura-graphql/",
    internalLink: true,
  },
  {
    demoType: "Live-stream",
    circleBgColor: "eventLightGreenBgColor",
    title: "Moving from OSS to Cloud",
    desc: "Learn how to migrate a Hasura OSS instance to Cloud and why it makes sense for you.",
    register: "View Recording",
    registerLink: "/events/webinar/migrate-oss-hasura-hasura-cloud/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    circleBgColor: "eventRedBgColor",
    title: "Hasura Community Call - August 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=tFtgy4mQ68o",
  },
  {
    demoType: "Fireside Chat",
    circleBgColor: "eventBlueBgColor",
    title: "Using Angular with GraphQL",
    desc: "Learn from our special guests about using GraphQL with Angular in our weekly series of frontend focused round-table discussions about GraphQL.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=SNMes6OZdsg&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=1",
  },
  {
    demoType: "Fireside Chat",
    circleBgColor: "eventBlueBgColor",
    title: "Using Relay with GraphQL",
    desc: "Learn from our special guests about using GraphQL with Relay in our weekly series of frontend focused round-table discussions about GraphQL.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=HapQ-nyT1os&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=2",
  },
  {
    demoType: "Fireside Chat",
    circleBgColor: "eventBlueBgColor",
    title: "Using Vue.js with GraphQL",
    desc: "Learn from our special guests about using GraphQL with Vue.js in our weekly series of frontend focused round-table discussions about GraphQL.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=ElvcUIgt7n0&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=4",
  },
  {
    demoType: "Workshop",
    circleBgColor: "eventBlueBgColor",
    title: "Learn how to use Hasura to build a realtime GraphQL application",
    desc: "How frontend developers can use Hasura to build a functioning GraphQL backend in minutes.",
    register: "View Recording",
    registerLink: "/community/hasura-realtime-graphql-workshop-with-ali/",
    internalLink: true,
  },
  {
    demoType: "Workshop",
    circleBgColor: "eventBlueBgColor",
    title: "Build GraphQL APIs in minutes - a workshop for frontend developers",
    desc: "How frontend developers can use Hasura to build a functioning GraphQL backend in minutes.",
    register: "View Recording",
    registerLink: "/community/workshop-graphql-apis-faster-frontend-developers/",
    internalLink: true,
  },
  {
    demoType: "Workshop",
    circleBgColor: "eventBlueBgColor",
    title: "Build a complete backend with GraphQL, serverless and the 3factor app architecture",
    desc: "Learn how to build a backend that’s both resilient & scalable.",
    register: "View Recording",
    registerLink: "/community/workshop-backend-3factor/",
    internalLink: true,
  },
  {
    demoType: "Workshop",
    circleBgColor: "eventBlueBgColor",
    title: "Scaling Hasura from 10 to 1M reqs/s",
    desc: "Scale a fully loaded GraphQL application built with Hasura (complete with auth & custom business logic) without any downtime.",
    register: "View Recording",
    registerLink: "/events/hasura-con-2020/scaling-hasura-from-10-to-1m-reqs-s/",
    internalLink: true,
  },
];
const ArchiveTopBanner = () => {
  const pastEvent = pastEventState.map((events, index) => {
    return <IndivArchive key={index} events={events} />;
  });
  return (
    <div className="positionRel wd100">
      <section className="commonSectionWrapper commonGrayBgColor borderRadiusBottom">
        <div className="containerWrapper">
          <div className="archiveWrapper">
            <div className="archiveHeaderWrapper">
              <h1 className="articleTitle">Events Archive</h1>
            </div>
            <div className="archiveListWrapper">{pastEvent}</div>
            <div className="commonLink">
              <Link to="/events/">
                Back to Events <div className="arrow">→</div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArchiveTopBanner;
