import React from "react";
const IndivArchive = ({ events }) => {
  return (
    <div className="archiveList">
      <div className="archiveLeft">
        <div className="articleDescSmall">
          <span>{events.demoType}</span>
          <div className={"circle ml-8 " + events.circleBgColor}></div>
        </div>
        <div className="articleSubTitle">{events.title}</div>
        <div className="articleDesc">{events.desc}</div>
      </div>
      <div className="commonLink">
        {events.internalLink ? (
          <a href={events.registerLink}>
            {events.register}
            <div className="arrow">→</div>
          </a>
        ) : (
          <a href={events.registerLink}>
            {events.register}
            <div className="arrow">→</div>
          </a>
        )}
      </div>
    </div>
  );
};

export default IndivArchive;
